<template>
  <div class="scheme-contain">
    <div class="search-contain">
      <Row>
        <Col :lg="{span: 8, offset: 8}" :md="{span: 12}">
          <Date-picker format="yyyy-MM-dd" type="daterange" placeholder="选择日期" @on-change="handleChangeTime"></Date-picker>
        </Col>
      </Row>
    </div>

    <div class="add-box">
      <Row>
        <Col span="3" offset="21">
          <div class="member-oprate clearfix">
            <a class="new-doc fr" v-on:click="addplan()">增加方案</a>
          </div>
        </Col>
      </Row>
    </div>

    <div class="table-box">
      <Table stripe :columns="columns" :data="planList" @on-selection-change="selectOne"></Table>
        <Row>
          <Col span="12">
          <div class="table-oprate">
            <Button @click="delPlan" v-show="power.indexOf('会员管理--删除会员信息权限') > -1">删除</Button>
          </div>
          </Col>
          <Col span="12">
          <span class="records">共{{pageTotal}}条记录</span>
          </Col>
        </Row>
    </div>

    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
      </div>
    </div>

    <Modal v-model="fpmodal" title="方案名称" @on-cancel="cancel">
      <div class="slotbody">
        <p>选择方案名称</p>
        <Select label-in-value @on-change="changePlan" placeholder="请选择">
          <Option :value="v.id" v-for="v in plan_type_list" :key="v.id">{{v.name}}</Option>
        </Select>
        <h3 class="reson-title">备注：</h3>
          <Input type="textarea" v-model="add_mark" :autosize="{minRows: 5, maxRows: 10}" placeholder="请输入...(可不填)"></Input>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okDistri">确定</Button>
        <Button @click="cancel">取消</Button>
      </div>
    </Modal>

    <Modal title="申请调整" v-model="adjustModal">
      <h3 class="tac">申请调整<em class="text_in_modal">{{adjustData.complete_time}}</em>上传的<em class="text_in_modal">{{adjustData.name}}</em></h3>
      <h3 class="reson-title">申请原因及诉求：</h3>
      <Input type="textarea" v-model="adjustForm.adjust_reason" :autosize="{minRows: 5, maxRows: 10}" placeholder="请输入..."></Input>
      <div slot="footer" class="tac">
        <i-button v-on:click="adjust_plan_method">提交</i-button>
      </div>
    </Modal>

    <Modal title="下载方案" v-model="downloadModal" :footerHide="true">
      <div class="download-contain">
        <div class="download-list">
          <Row>
            <Col span="12" class="tac"><h3>{{downloadForm.name}}</h3></Col>
            <Col span="12" class="tac"><i-button><a :href="downloadForm.file" target="_blank" class="download">下载</a></i-button></Col>
          </Row>
        </div>
      </div>
    </Modal>

    <!--查看方案备注modal-->
    <Modal v-model="addMarkmodal" title="查看备注">
      <div class="slotbody">
        <div class="feed-txt break-word">{{add_mark_detail}}</div>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okCancel">确定</Button>
      </div>
    </Modal>

    <Modal title="提醒" v-model="successModal" :footerHide="true">
      <h3 class="tac">调整<em class="text_in_modal">{{adjustData.complete_time}}</em>上传的<em class="text_in_modal">{{adjustData.name}}</em></h3>
      <div class="success-contain">
        <div class="tac">
          <p><img src="@/assets/img/icon-sure.png" width="50px" height="50px" alt="" class="icon-sure">申请提交成功，请耐心等待调整结果</p>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script type="text/ecmascript-6">
import planService from '@/services/planService';
export default {
	data() {
		return {
			searchForm: {
				page: 1,
				size: 10,
				start_time: '',
				end_time: '',
				member_id: this.$route.params.member_id,
			},
			plan_type_list: [],
			adjustForm: {
				plan_id: '',
				adjust_reason: '',
			},
			del: {
				plan_ids: '',
			},
			del_one: {
				plan_ids: '',
			},
			downloadForm: {
				file: '',
				name: '',
			},
			adjustData: {
				name: '',
				complete_time: '',
			},
			adjustModal: false,
			downloadModal: false,
			successModal: false,
			fpmodal: false,
			addMarkmodal: false,
			choose_plan_name: '',
			add_mark_detail: '',
			add_mark: '',
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '方案申请日期', key: 'create_time', align: 'center' },
				{ title: '方案上传日期', key: 'complete_time', align: 'center' },
				{ title: '方案名称', key: 'name', align: 'center' },
				{ title: '状态', key: 'status_string', align: 'center' },
				{
					title: '备注',
					key: 'add_mark',
					align: 'center',
					render: (h, params) => {
						if (params.row.add_mark) {
							return h('div', [
								h(
									'a',
									{
										style: {
											color: '#00cb84',
										},
										on: {
											click: () => {
												this.goSee(params.index);
											},
										},
									},
									'查看',
								),
							]);
						} else {
							return h('div', params.row.add_mark);
						}
					},
				},
				{
					title: '操作',
					key: 'action',
					width: 300,
					align: 'center',
					render: (h, params) => {
						if (params.row.status_string === '已审核 已通过') {
							if (this.power.indexOf('会员管理--删除会员信息权限') > -1) {
								return h('div', [
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.download_plan(params.index);
												},
											},
										},
										'下载',
									),
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.adjust_plan(params.index);
												},
											},
										},
										'申请调整',
									),
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.del_one_Plan(params.index);
												},
											},
										},
										'删除',
									),
								]);
							} else {
								return h('div', [
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.download_plan(params.index);
												},
											},
										},
										'下载',
									),
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.adjust_plan(params.index);
												},
											},
										},
										'申请调整',
									),
								]);
							}
						} else if (params.row.status_string === '调整件') {
							if (this.power.indexOf('会员管理--删除会员信息权限') > -1) {
								return h('div', [
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.download_plan(params.index);
												},
											},
										},
										'下载',
									),
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.adjust_plan(params.index);
												},
											},
										},
										'申请调整',
									),
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.del_one_Plan(params.index);
												},
											},
										},
										'删除',
									),
								]);
							} else {
								return h('div', [
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.download_plan(params.index);
												},
											},
										},
										'下载',
									),
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.adjust_plan(params.index);
												},
											},
										},
										'申请调整',
									),
								]);
							}
						} else if (
							params.row.status_string === '已调整' ||
							params.row.status_string === '待调整'
						) {
							if (this.power.indexOf('会员管理--删除会员信息权限') > -1) {
								return h('div', [
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.download_plan(params.index);
												},
											},
										},
										'下载',
									),
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.del_one_Plan(params.index);
												},
											},
										},
										'删除',
									),
								]);
							} else {
								return h('div', [
									h(
										'a',
										{
											style: {
												margin: '0 10px',
												color: '#ff8400',
											},
											on: {
												click: () => {
													this.download_plan(params.index);
												},
											},
										},
										'下载',
									),
								]);
							}
						}
					},
				},
			],
			planList: [],
			pageTotal: 0,
			power: [],
		};
	},
	computed: {
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},
	created() {
		this.getPlanList();
		this.getPlanTypeList();
		this.power = this.userInfo.power;
	},
	methods: {
		getPlanList() {
			planService.getPlanList(this.searchForm).then((data) => {
				this.planList = data.list;
				this.pageTotal = data.row_size;
				this.del.plan_ids = '';
			});
		},
		goSee(index) {
			this.add_mark_detail = this.planList[index].add_mark;
			this.addMarkmodal = true;
		},
		okCancel() {
			this.addMarkmodal = false;
		},
		getPlanTypeList() {
			planService.planType().then((data) => {
				this.plan_type_list = data.plan_type_list;
			});
		},
		changePlan(data) {
			this.choose_plan_name = data.label;
		},
		adjust_plan_method() {
			if (this.validateData()) {
				planService.adjustPlan(this.adjustForm).then(() => {
					this.adjustModal = false;
					this.successModal = true;
					this.getPlanList();
				});
			}
		},
		validateData() {
			if (!this.adjustForm.adjust_reason) {
				this.$Message.warning('请输入申请的原因及诉求');
				return false;
			} else {
				return true;
			}
		},
		handleChangeTime(value) {
			this.searchForm.page = 1;
			this.searchForm.start_time = value[0];
			this.searchForm.end_time = value[1];
			this.getPlanList();
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getPlanList();
		},
		selectOne(selection) {
			let arrId = [];
			for (var i = 0; i < selection.length; i++) {
				arrId.push(selection[i].id);
			}
			this.del.plan_ids = arrId.join(',');
		},
		delPlan() {
			if (!this.del.plan_ids) {
				this.$Message.warning('请先选择要删除的方案');
			} else {
				this.$Modal.confirm({
					title: '确认',
					content: '确定删除所选方案吗',
					onOk: () => {
						this.okdel();
					},
				});
			}
		},
		del_one_Plan(index) {
			var obj = this.planList[index];
			this.del_one.plan_ids = obj.id;
			this.$Modal.confirm({
				title: '确认',
				content: '确定删除该方案吗',
				onOk: () => {
					planService.deletePlan(this.del_one).then(() => {
						// this.searchForm.page = 1
						this.getPlanList();
					});
				},
			});
		},
		okdel() {
			planService.deletePlan(this.del).then(() => {
				// this.searchForm.page = 1
				this.getPlanList();
			});
		},
		addplan() {
			this.fpmodal = true;
		},
		adjust_plan(index) {
			this.adjustData.name = this.planList[index].name;
			this.adjustData.complete_time = this.planList[index].complete_time;
			this.adjustForm.plan_id = this.planList[index].id;
			this.adjustModal = true;
		},
		download_plan(index) {
			this.downloadForm.file = this.planList[index].url;
			this.downloadForm.name = this.planList[index].name;
			this.downloadModal = true;
		},
		cancel() {
			this.fpmodal = false;
		},
		okDistri() {
			if (!this.choose_plan_name) {
				this.$Message.warning('请选择方案名称');
			} else {
				planService
					.addPlan({
						member_id: this.$route.params.member_id,
						plan_name: this.choose_plan_name,
						add_mark: this.add_mark,
					})
					.then(() => {
						this.getPlanList();
						this.fpmodal = false;
					});
			}
		},
	},
};
</script>
<style lang="css" scoped>
.pt50 {padding-top: 50px;}
.text_in_modal {
  font-size: 18px;
  color: #424e67;
  padding: 0 10px;
}
.reson-title {
  margin-bottom: 13px;
  margin-top: 20px;
}
.download-contain {
  padding: 25px 50px;
}
.download-contain .download-list {
  margin-bottom: 15px;
}
.download-contain .download-list h3 {
  line-height: 30px;
}
.success-contain {
  background: #f1f2f7;
  padding: 30px 0;
  margin: 20px -16px -16px -16px;
}
.icon-sure {
  margin-right: 15px;
}
.new-doc {padding-right: 35px; }
</style>
